<template>
  <div>
    <div ref="document">
      <div v-for="(chouhyouData, chouhyouIndex) in listChouhyou" v-bind:key="chouhyouIndex + chouhyouData.page" :aria-label="`${constData.chouhyouId}-${chouhyouIndex}`">
        <div>
          <TemplateNormal :id="constData.chouhyouId + chouhyouIndex" />
        </div>
      </div>
    </div>
    <v-btn
      v-if="showButton"
      class="mb-2 mx-2"
      dark
      color="secondary"
      rounded
      fixed
      center
      bottom
      @click="outputPdf"
    >
      <v-icon left>mdi-file-document</v-icon>{{ outputMessage }}
    </v-btn>
  </div>
</template>
<script>
import TemplateNormal from '@/assets/svg/order_record/temp_delivery_slip/normal.svg';
import { executeHanbaikanriSqls, executeTransactSqls, base64Decode, setPaperA4, splitMultiRowString } from '@/assets/js/common';
import { ALERT_MESSAGE_COMMON_ERROR, CONFIRM_MESSAGE_OUTPUT_PDF, CONFIRM_MESSAGE_RE_OUTPUT_PDF, ALERT_OUTPUT_COMPLETE } from "@/assets/js/dialog_messages";
import { listV_HACCHU_DATA_WITH_MEISAI, getM_SHOKISETTEI, getT_CHOHYO_COUNTER } from '@/graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import html2pdf from 'html2pdf.js'
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: 'TempDeliverySlip',
  /* コンポーネント */
  components: {
    TemplateNormal
  },
  /* パラメータ */
  props:['params'],
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 6,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: '仮伝納品書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '210mm',
        height: '295.01mm',
      },
      addProductCnt: 0,
      listChouhyou: [],
      replacementsCommon: [],

      // 取得した発注データリスト
      searchItemList: [],
      // 仮伝票番号
      tempSlipNum: 0,
      // 部門発注番号
      hacchuNum: 0,

      // デコードしたparams
      parsedParams: null,
      // 情報が揃った発注データ
      fixedOrders: [],

      // PDF出力ボタン表示フラグ
      showButton: false,
      // PDF出力ボタン文言
      outputMessage: '',

      // PDFファイルS3情報
      fileInfo: {
        filePath: '',
        fileName: '',
      },
    }
  },
  async created() {
    // ヘッダ・フッタを非表示
    this.$store.commit("setShowNav", false);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      return;
    }
  },
  /* マウント */
  async mounted() {
    // ログインユーザー情報取得
    await this.getLoginUserInfo();
    // 発注データを取得してプレビュー画面を生成
    await this.createPreview();
  },
  /* 関数群 */
  methods:{
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"

      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;

      // console.log('loginUserInfo');
      // console.log(this.loginUserInfo);

      // データ取得
      let condition = { BUMON_CD: this.loginUserInfo.BUMON_CD };
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.getM_SHOKISETTEI) {
          // データを取得できた場合
          // ログイン中ユーザーの所属部門情報を取得
          this.departmentInfo = result.data.getM_SHOKISETTEI;
          return true;
        } else {
         if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /* 発注データを設定してプレビューを生成 */
    async createPreview(){
      // メソッド名を定義
      // const method_name = "createPreview";

      try {
        // 引数をデコード
        const decodedParams = await base64Decode(this.params);
        // console.log({decodedParams});
        // JSONパース
        this.parsedParams = JSON.parse(decodedParams);

        // PDF出力文言
        if (this.parsedParams.tempPrintDone) {
          this.outputMessage = 'PDF出力';
        } else {
          this.outputMessage = '確定してPDF出力';
        }

        // 仮伝情報を取得する（発注明細データ取得VIEW）
        const orders = await this.getSearchItemList();
        // console.log('getSearchItemList end');

        // 1件も取得できなかった場合
        if (orders.length == 0) {
          alert("条件に合うレコードがありません。")
          // ローディングを解除
          this.$store.commit("setLoading", false);
          return;
        }
        // 仮伝情報を取得する（発注明細データ取得VIEW以外）
        const updatedOrders = await this.getOrderList(orders);
        // console.log('getOrderList end');
        // console.log("仮伝で必要な情報を取得する  " + updatedOrders.length);

        // console.log({updatedOrders});

        // データが存在すればプレビュー画面生成
        if(updatedOrders && updatedOrders.length > 0){
          // レイアウト設定
          await setPaperA4();

          // 明細データを仮伝番号でGroupBy
          const dataByTempSlipNumber = updatedOrders.reduce((groups, item) => {
            const group = (groups[item.tempSlipNo] || []);
            group.push(item);
            groups[item.tempSlipNo] = group;
            return groups;
          }, {});
          // console.log({dataByTempSlipNumber});

          // 件数からどの帳票テンプレートを用いるか、何ページ用意するか等を設定
          await this.initListChouhyou(dataByTempSlipNumber);
          // 得意先ごとのデータに帳票番号と帳票内の項番をセット
          const dataByTempSlipNumberWithIndex = await this.setItemNumbers(dataByTempSlipNumber);
          // ページ毎の置換文字列設定
          await this.createReplacementsPage(dataByTempSlipNumberWithIndex);
          // 作成した置換文字データをSVGファイルに設定
          // await this.setChouhyou();
          const chouhyouSet = await this.setChouhyou();

          if(chouhyouSet) {
            // 発注情報を補完
            this.fixedOrders = updatedOrders;
            // 「確定してPDF出力」ボタン表示
            this.showButton = true;
          }
        }

        // ローディングOFF
        this.$store.commit("setLoading", false);
      } catch (error) {
        console.log(error);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'createPreview', TYPE["0004"], error);
        // ローディングOFF
        this.$store.commit("setLoading", false);
        // アラート出力
        alert(ALERT_MESSAGE_COMMON_ERROR);
      }
    },
    /**
     * 仮伝情報を取得する（発注明細データ取得VIEW）
     */
    async getSearchItemList() {
      // console.group('getSearchItemList');
      // 返却するリスト
      let orders = [];
      // console.log({orders});
      // データ取得
      let condition = {
        where_options: this.parsedParams.whereOptions,
      };
      // console.log({condition});
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI, condition)
        );
        // 結果判定
        if (result.data.listV_HACCHU_DATA_WITH_MEISAI) {
          // データを取得できた場合
          // 取得結果を格納
          let resultData = result.data.listV_HACCHU_DATA_WITH_MEISAI;
          // console.log({resultData});
          for (const data of resultData) {
            // console.log({data});
            orders.push({
              orderDataId: data.HACCHU_DATA_ID,
              orderNo: data.CHUMON_NO,
              orderBranchNo: data.MEISAI_NO,
              productCode: data.SHOHIN_CD,
              cssClientCode: data.CSS_TOKUISAKI_CD,
              cssClientName: data.CSS_TOKUISAKI_NAME,
              tempSlipNo: data.URIDEN_NO,
              tempSlipPrintDate: data.KARIDEN_DATE,
              departmentName: this.departmentInfo.BUMON_NAME,
              tokuiManagerName: null,
              tokuiManagerCode: null,
              productName: data.SHOHIN_NAME,
              makerName: null,
              returnProductFlg: null,
              amount: data.SURYO_SU,
              net: data.NET_GK,
              customerWholesaleCost: data.OKYAKUSAMA_OROSHIKAKAKU_GK,
              customerWholesalePrice: null,
              lastCost: data.SAISHU_SHIIRE_KAKAKU_GK,
              spotName: data.GENBA_NAME,
              managerName: data.TANTOSHA_NAME,
              arrivalPurchasePrice: data.NYUKAJI_SHIIRE_KAKAKU_GK
            });
          }
          // console.log({orders});
          // console.groupEnd('getSearchItemList');
          return orders;
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          return orders;
        }
      } catch (error) {
        // Exception発生の場合
        /// ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, 'getSearchItemList', TYPE["0003"], error);
        return [];
      }
    },
    /**
     * 仮伝情報を取得する（発注明細データ取得VIEW以外）
     * ・仮伝票／NOを更新する。
     * ・メーカー、返品、担当者名、得意先単価の取得
     */
    async getOrderList(orders) {
      // console.group('getOrderList');
      // console.log({orders});
      // メソッド名を定義
      const method_name = "getOrderList";

      // 仮伝票／NOを更新する。
      let beforeCssClientCode = null;
      let beforeSpotName = null;
      let beforeTempSlipNum = null;
      let formDetailNo = null;
      let cntUpFlg = false;
      for (const data of orders) {
        // console.log({data});
        // const tempSlipNo = data.tempSlipNo;
        // console.log({tempSlipNo});
        if (data.tempSlipNo == null) {

          let tempSlipNum = -1;

          // カウントアップフラグの更新
          if (data.cssClientCode !== beforeCssClientCode) {
            formDetailNo = 0;
            cntUpFlg = true;
          } else if (data.spotName !== beforeSpotName) {
            if (this.parsedParams.serialNumberCondition == 1) {
              formDetailNo = 0;
              cntUpFlg = true;
            } else {
              if (formDetailNo == 6) {
                formDetailNo = 0;
                cntUpFlg = true;
              } else {
                tempSlipNum = beforeTempSlipNum;
              }
            }
          } else if (formDetailNo == 6) {
            formDetailNo = 0;
            cntUpFlg = true;
          } else {
            tempSlipNum = beforeTempSlipNum;
          }

          if (cntUpFlg == true) {
            // 仮伝票カウンタを取得
            tempSlipNum = await this.getTempSlipNum();

            if (tempSlipNum < 0) {
              return false;
            }
            // console.log("仮伝票カウンタを取得  " + tempSlipNum)
            // カウンタをUPDATE
            let sqlList = [
              `UPDATE T_CHOHYO_COUNTER SET ` +
                ` KARI_DENPYO_NO = ${tempSlipNum}` +
                ` WHERE BUMON_CD = ${this.loginUserInfo.BUMON_CD};`
            ];
            const result = await executeTransactSqls(this.$route.name, method_name, sqlList)
            if (!result) {
              return false;
            }
            beforeTempSlipNum = tempSlipNum;
          }
          formDetailNo = formDetailNo + 1;

          // console.log({tempSlipNum});

          data.tempSlipNo =  tempSlipNum;
        }
        beforeCssClientCode = data.cssClientCode;
        beforeSpotName = data.spotName;
        cntUpFlg = false;
      }

      /**
       * 仮伝で必要な情報を取得
       */
      // メーカー、返品情報の取得
      let sqlSearchList = [];
      // 商品コードを抜き出し
      const originalProductCodeList = orders.map(function(element) {
        return element.productCode;
      });

      // 重複を削除してIN句の文字列を作成
      const productCodeList = Array.from(new Set(originalProductCodeList))
      const productCodes = productCodeList.join(',');
      // SQL:0
      sqlSearchList.push(
        "SELECT SH_SHOCD, SH_MAKERNAME, SH_HENPINFLG, SH_URITNA FROM SHOHIN " +
        `WHERE SH_SHOCD IN (${productCodes})`
      );

      // 担当者名の取得
      // 取引先コードを抜き出し
      const originalClientCodeList = orders.map(function(element) {
        return element.cssClientCode;
      });
      // 重複を削除してIN句の文字列を作成
      const clientCodeList = Array.from(new Set(originalClientCodeList))
      const clientCodes = clientCodeList.join(',');

      // SQL:1
      sqlSearchList.push(
        "SELECT T1.TK_TORCD, T1.TK_TANCD, T2.TT_TANRNM " +
        "FROM TOKUI T1 LEFT OUTER JOIN TANTO T2 ON T1.TK_TANCD = T2.TT_TANCD " +
        `WHERE T1.TK_TORCD IN (${clientCodes})`
      );

      // 得意先単価の取得
      let tTankaSQL = "";
      tTankaSQL += "SELECT TAT_TORTN, TAT_TORCD, TAT_SHOCD FROM TTANKA";
      tTankaSQL += " WHERE";
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        tTankaSQL += ` (TAT_BASCD = ${this.loginUserInfo.BUMON_CD} AND TAT_TORCD = ${order.cssClientCode} AND TAT_SHOCD = ${order.productCode})`;
        if (i !== orders.length-1) {
          tTankaSQL += " OR";
        }
      }
      // console.log({tTankaSQL});
      // SQL:2
      sqlSearchList.push(tTankaSQL);

      // 販売管理DBからデータを取得
      let response = await executeHanbaikanriSqls(this.$route.name, method_name, sqlSearchList)
      if (response.body.error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"]}, this.$route.name, 'getOrderList', TYPE["0003"], response.body.error);
        return false;
      }
      const responseData = response.body.data;

      // メーカー、返品情報セット
      // console.log(responseData[0]);
      let productSearchResultList = [];
      if(responseData[0]) {
        for (const targetData of orders) {
          let data = responseData[0].find(
            (items) => items.SH_SHOCD == targetData.productCode
          );
          if (data !== void 0) {
            targetData.makerName = data.SH_MAKERNAME;
            targetData.returnProductFlg = data.SH_HENPINFLG; // 1：返品不可 0：なし
          }
        }
        productSearchResultList = responseData[0];
      }

      // 担当者名のセット
      // console.log(responseData[1]);
      for (const targetData of orders) {
        let data = responseData[1].find(
          (items) => items.TK_TORCD == targetData.cssClientCode
        );
        // 担当者コード
        targetData.tokuiManagerCode = data.TK_TANCD ?? null;
        // 担当者名
        if(data.TT_TANRNM) {
          targetData.tokuiManagerName = data.TT_TANRNM;
        } else {
          alert("売上担当者名の登録がない得意先があります。\n担当者名が空欄で出力されます。" +
          "\n\n伝票No.　：" + targetData.tempSlipNo + "\n得意先名  ：" + targetData.cssClientName);
        }
      }

      // 得意先単価のセット
      // console.log(responseData[2]);
      for (const targetData of orders) {
        let price = responseData[2].find(
          (items) => items !== void 0 && (items.TAT_TORCD == targetData.cssClientCode && items.TAT_SHOCD == targetData.productCode)
        );
        let product = null;
        if(productSearchResultList) {
          product = productSearchResultList.find(
            (items) => items.SH_SHOCD == targetData.productCode
          );
        }
        // console.log({price});
        // console.log({product});
        if(!targetData.customerWholesaleCost) {
          if (price) {
            targetData.customerWholesaleCost = price.TAT_TORTN;
          } else if (product) {
            targetData.customerWholesaleCost = product.SH_URITNA;
          }
        }
        if(targetData.customerWholesaleCost) {
          targetData.customerWholesalePrice = targetData.amount * targetData.customerWholesaleCost;
        }
      }

      // console.log({orders});
      // console.groupEnd('getOrderList');
      return orders;
    },
    /**
     * 仮伝票カウンタを取得する
     */
    async getTempSlipNum() {
      console.log('getTempSlipNum');
      // コード最大値の取得
      let registerOrderNo;
      // データ取得
      let condition = { BUMON_CD: this.loginUserInfo.BUMON_CD };
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getT_CHOHYO_COUNTER, condition)
        );
        // 結果判定
        if (result.data.getT_CHOHYO_COUNTER) {
          // データを取得できた場合
          // ログイン中ユーザーの所属部門情報を取得
          let resultData = result.data.getT_CHOHYO_COUNTER;
          registerOrderNo =
            resultData.KARI_DENPYO_NO === 99999
              ? 1
              : resultData.KARI_DENPYO_NO + 1;
          return registerOrderNo;
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // ログ出力(exception)
            addLog('ERROR', {message: MSG.ERROR["3006"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, 'getTempSlipNum', TYPE["0003"], result.errors);
            return -1;
          } else {
            // データ0件時
          }
        }
      } catch (error) {
        // Exception発生の場合
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, 'getTempSlipNum', TYPE["0003"], error);
        return -1;
      }
    },
    /* 帳票リスト初期化 */
    async initListChouhyou(dataByTempSlipNumber){
      // console.group('initListChouhyou');
      // console.log({dataByTempSlipNumber});
      for (const [key, customerData] of Object.entries(dataByTempSlipNumber)) { // eslint-disable-line
        // console.log({customerData});
        // const len = customerData.length;
        // console.log({len});

        let productCnt = customerData.length;

        let productCntRemain = productCnt;
        let page = 1;
        while (productCntRemain > this.constData.cntNormal) {
          // 残りの製品件数が最終帳票の件数に収まらない場合、帳票を出力し続ける
          this.listChouhyou.push({page: page, replacements: []});
          productCntRemain -= this.constData.cntNormal;
          page++;
        }
        // 最終ページ
        this.listChouhyou.push({page: page, replacements: []});
      }
      // const chohyoList = this.listChouhyou;
      // console.log({chohyoList});
      // console.groupEnd('initListChouhyou');
    },
    /* 発注データに何番目の帳票のデータか、その帳票内の何番目のデータかを設定 */
    async setItemNumbers(dataByTempSlipNumber){
      // console.group('setItemNumbers');
      // console.log({dataByTempSlipNumber});
      let chouhyouNum = 0;
      let dataByTempSlipNumberWithIndex = [];
      // 得意先ごとにループ
      for (const [key, customerData] of Object.entries(dataByTempSlipNumber)) { // eslint-disable-line
        // console.log({customerData});
        // const len = customerData.length;
        // console.log({len});
        // 得意先のデータの明細ごとにループ
        for (let customerItemIndex = 0; customerItemIndex < customerData.length; customerItemIndex++) {
          // 何番目の帳票か設定
          customerData[customerItemIndex].chouhyouIndex = chouhyouNum + Math.floor(customerItemIndex / this.constData.cntNormal);
          // 帳票内での項番を設定
          customerData[customerItemIndex].indexInChouhyou = customerItemIndex % this.constData.cntNormal;
        }
        chouhyouNum += Math.ceil(customerData.length / this.constData.cntNormal);
        // console.log({customerData});
        dataByTempSlipNumberWithIndex.push(customerData);
      }
      // console.log({dataByTempSlipNumberWithIndex});
      // console.groupEnd('setItemNumbers');
      return dataByTempSlipNumberWithIndex;
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(dataByTempSlipNumberWithIndex){
      // console.group('createReplacementsPage');
      // let productsIndex = 0;
      // let whiteFlg = false;
      // 得意先ごとにループ
      for (const customerData of dataByTempSlipNumberWithIndex) {
        // 得意先データを帳票番号でGroupByして1帳票ごとのデータを取得
        const dataByChouhyou = customerData.reduce((groups, item) => {
          const group = (groups[item.chouhyouIndex] || []);
          group.push(item);
          groups[item.chouhyouIndex] = group;
          return groups;
        }, {});

        // console.log({dataByChouhyou});

        // 得意先のデータを1帳票分ごとにループ
        for (const [key, meisaiData] of Object.entries(dataByChouhyou)) {
          // console.log({meisaiData});
          let chouhyouIndex = Number(key);

          // SVGファイルの置換用文字列
          let replacements = this.listChouhyou[chouhyouIndex].replacements;

          // 帳票毎に設定可能な製品の件数
          let productCntByChouhyou = this.constData.cntNormal;

          /* ヘッダ */
          // 営業所情報
          await this.setOfficesData();

          for (let i = 0; i < 3; i++) {
            // 日付
            replacements.push({key: `%日付${i}%`, value: meisaiData[0].tempSlipPrintDate, textAnchor: 'end', textLength: 194});
            // 部門名
            replacements.push({key: `%部門名${i}%`, value: this.departmentInfo.BUMON_NAME ?? '', textAnchor: 'end', textLength: 193});
            // 得意先名
            replacements.push({key: `%得意先名${i}%`, value: meisaiData[0].cssClientName ?? '', textAnchor: 'start', textLength: 549});
            // 得意先コード
            replacements.push({key: `%CCD${i}%`, value: meisaiData[0].cssClientCode ?? '', textAnchor: 'middle', textLength: 160});
            // 仮伝番号
            replacements.push({key: `%仮伝番${i}%`, value: meisaiData[0].tempSlipNo ?? '', textAnchor: 'start', textLength: 165});
            // 担当
            replacements.push({key: `%担${i}%`, value: meisaiData[0].tokuiManagerName ?? '', textAnchor: 'start', textLength: 113});
            // 担当者コード
            replacements.push({key: `%T${i}%`, value: meisaiData[0].tokuiManagerCode ?? '', textAnchor: 'middle', textLength: 89});
          }

          /**
           * 製品
           * */
          // 納品書（売上計上用）・仮納品書・物品受領書の3パターン出力
          for (let j = 0; j < productCntByChouhyou; j++) {
            // 納品書（売上計上用）・仮納品書・物品受領書の3パターン出力
            for (let patternIndex = 0; patternIndex < 3; patternIndex++) {
              let addIndex = patternIndex * this.constData.cntNormal;
              // 商品が一つの場合インデックス0・6・12に同じデータが入る
              let index = j + addIndex;

              // 注番
              replacements.push({key: `%ON${index}%`, value: meisaiData[j]?.orderNo ? `${this.hacchuNum}-${meisaiData[j].orderNo}` : '', textAnchor: 'middle', textLength: 156});
              // 品番CD
              replacements.push({key: `%P${index}%`, value: meisaiData[j]?.productCode ?? '', textAnchor: 'middle', textLength: 118});
              // 品名
              replacements.push({key: `%PN${index}%`, value: meisaiData[j]?.productName ?? '', textAnchor: 'start', textLength: 500});
              // 数量
              replacements.push({key: `%${index}%`, value: meisaiData[j]?.amount ? meisaiData[j].amount.toLocaleString() : '', textAnchor: 'end', textLength: 87});
              // 単価・金額
              // 単価記載方法が「単価あり」「単価なし(仮伝のみ)」
              if (this.parsedParams.unitPriceDisplay !== '1') {
                switch (this.parsedParams.unitPriceDisplay) {
                  case '0':
                    // console.log('case 0')
                    // お客様卸価格が0円以上の場合単価・金額を出力
                    // 単価
                    replacements.push({key: `%U${index}%`, value: meisaiData[j]?.customerWholesaleCost > 0 ? meisaiData[j]?.customerWholesaleCost.toLocaleString() : '', textAnchor: 'end', textLength: 138});
                    // 金額
                    replacements.push({key: `%T${index}%`, value: meisaiData[j]?.customerWholesaleCost > 0 ? meisaiData[j]?.customerWholesalePrice.toLocaleString() : '', textAnchor: 'end', textLength: 180});
                    // 原価
                    // 商品ｺｰﾄﾞ５桁の物は発注簿システム上の入荷時仕入価格を印字
                    // 商品ｺｰﾄﾞ６桁の物は発注簿システム上の最終仕入価格
                    if(meisaiData[j]?.productCode?.toString()?.length === 5) {
                      replacements.push({key: `%C${index}%`, value: meisaiData[j]?.arrivalPurchasePrice > 0 ? meisaiData[j].arrivalPurchasePrice.toLocaleString() : '', textAnchor: 'end', textLength: 180});
                    } else if(meisaiData[j]?.productCode?.toString()?.length === 6) {
                      replacements.push({key: `%C${index}%`, value: meisaiData[j]?.lastCost > 0 && meisaiData[j]?.net > 0 ? meisaiData[j].lastCost.toLocaleString() : '', textAnchor: 'end', textLength: 180});
                    } else {
                      replacements.push({key: `%C${index}%`, value: meisaiData[j]?.lastCost > 0 ? meisaiData[j].lastCost.toLocaleString() : '', textAnchor: 'end', textLength: 180});
                    }
                    break;
                  case '2':
                    // 「単価なし(仮伝のみ)」の場合、「納品書（売上計上用）」のみに単価・金額を出力
                    if(index < this.constData.cntNormal) {
                      // お客様卸価格が0円以上の場合単価・金額を出力
                      // 単価
                      replacements.push({key: `%U${index}%`, value: meisaiData[j]?.customerWholesaleCost > 0 ? meisaiData[j]?.customerWholesaleCost.toLocaleString() : '', textAnchor: 'end', textLength: 138});
                      // 金額
                      replacements.push({key: `%T${index}%`, value: meisaiData[j]?.customerWholesaleCost > 0 ? meisaiData[j]?.customerWholesalePrice.toLocaleString() : '', textAnchor: 'end', textLength: 180});
                      // 原価
                      // 商品ｺｰﾄﾞ５桁の物は発注簿システム上の入荷時仕入価格を印字
                      // 商品ｺｰﾄﾞ６桁の物は発注簿システム上の最終仕入価格
                      if(meisaiData[j]?.productCode?.toString()?.length === 5) {
                        replacements.push({key: `%C${index}%`, value: meisaiData[j]?.arrivalPurchasePrice > 0 ? meisaiData[j].arrivalPurchasePrice.toLocaleString() : '', textAnchor: 'end', textLength: 180});
                      } else if(meisaiData[j]?.productCode?.toString()?.length === 6) {
                        replacements.push({key: `%C${index}%`, value: meisaiData[j]?.lastCost > 0 && meisaiData[j]?.net > 0 ? meisaiData[j].lastCost.toLocaleString() : '', textAnchor: 'end', textLength: 180});
                      } else {
                        replacements.push({key: `%C${index}%`, value: meisaiData[j]?.lastCost > 0 ? meisaiData[j].lastCost.toLocaleString() : '', textAnchor: 'end', textLength: 180});
                      }
                    } else {
                      // 単価
                      replacements.push({key: `%U${index}%`, value: '', textAnchor: 'end', textLength: 138});
                      // 原価
                      replacements.push({key: `%C${index}%`, value: '', textAnchor: 'end', textLength: 180});
                      // 金額
                      replacements.push({key: `%T${index}%`, value: '', textAnchor: 'end', textLength: 180});
                    }
                    break;
                  default:
                    break;
                }
              } else {
                // 単価
                replacements.push({key: `%U${index}%`, value: '', textAnchor: 'end', textLength: 138});
                // 原価
                replacements.push({key: `%C${index}%`, value: '', textAnchor: 'end', textLength: 180});
                // 金額
                replacements.push({key: `%T${index}%`, value: '', textAnchor: 'end', textLength: 180});
              }
              // 備考（現場名を出力）
              this.setSummary(replacements, index, meisaiData[j]?.spotName ?? '');
            }
          }
          // 合計（単価記載方法が「単価あり」「単価なし(仮伝のみ)」、かつ合計金額が0円以上の場合出力）
          let totalPrice = meisaiData.reduce(function(sum, item) {
            return sum + item.customerWholesalePrice;
          }, 0);
          if (this.parsedParams.unitPriceDisplay !== '1' && (totalPrice && totalPrice > 0)) {
            replacements.push({key: `%合計金額%`, value: totalPrice.toLocaleString(), textAnchor: 'end', textLength: 180});
          } else {
            replacements.push({key: `%合計金額%`, value: '', textAnchor: 'end', textLength: 180});
          }
        }
      }
      // console.groupEnd('createReplacementsPage');
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData() {
      // 部門コード
      const user = this.$store.getters.user;
      const bumonCd = user.BUMON_CD;

      // 営業所データ取得
      let result = await API.graphql(graphqlOperation(getM_SHOKISETTEI, {BUMON_CD: bumonCd}));
      // console.log({result});
      const bumon = result.data.getM_SHOKISETTEI;
      // console.log({bumonData});
      // // TODO データが存在しなかった場合の処理
      // const bumon = bumonData[0];
      // console.log({bumon});

      // 部門発注番号
      this.hacchuNum = bumon.HACCHU_NO ?? 0;
    },
    /* 置換文字列に備考を設定 */
    async setSummary(replacements, rowNum, summary) {
      let arySummary = splitMultiRowString(summary, 10);
      for (let i = 0; i < 2; i++) {
        if (i < arySummary.length) {
          replacements.push({key: `%S${rowNum}-${i}%`, value: arySummary[i], textAnchor: 'start', textLength: 353});
        } else {
          replacements.push({key: `%S${rowNum}-${i}%`, value: '', textAnchor: 'start', textLength: 353});
        }
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      // console.log('setChouhyou');
      for (let i = 0; i < this.listChouhyou.length; i++){
        // console.log(`this.constData.chouhyouId + this.listChouhyou[i].page = ${this.constData.chouhyouId + i}`);
        let svgDoc = document.getElementById(this.constData.chouhyouId + i);
        this.setReplacements(svgDoc, this.listChouhyou[i].replacements);
        await this.setSize(svgDoc);
      }
      return true;
    },
    /* 「確定してPDF出力」ボタン押下時処理 */
    async outputPdf() {
      let confirmMessage =
        this.parsedParams.tempPrintDone ?
        CONFIRM_MESSAGE_RE_OUTPUT_PDF :
        CONFIRM_MESSAGE_OUTPUT_PDF;
      if (!confirm(confirmMessage)) {
        return;
      }
      try {
        // ローディングON
        console.log('loading on');
        this.$store.commit("setLoading", true);

        // 帳票をPDFダウンロード
        const result = await this.exportToPDF();

        // 仮伝出力フラグを更新
        if (!this.parsedParams.tempPrintDone && result) {
          await this.updateFlg(this.fixedOrders);
        }

        // ローディングを解除
        console.log('loading off');
        this.$store.commit("setLoading", false);
        // 完了メッセージ
        alert(ALERT_OUTPUT_COMPLETE);
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'outputPdf', TYPE["0003"], error);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // アラート出力
        alert(ALERT_MESSAGE_COMMON_ERROR);
      }
    },
    /* 帳票をPDFダウンロード */
    async exportToPDF() {
      // console.log('export to PDF');
      // console.log(this.$refs.document);
      try {
        const pages = Array.from(document.querySelectorAll(`div[aria-label^="${this.constData.chouhyouId}-"]`));

        let worker = html2pdf()
          .set({
            filename: '仮伝納品書.pdf',
            image: {type: 'jpeg', quality: 0.55},
            html2canvas: { scale: 2 },
          })
          .from(pages[0])

        if (pages.length > 1) {
          // jsPDFインスタンスを生成
          worker = worker.toPdf()

          // 1ページずつPDFをレンダリング
          for (const page of pages.slice(1)) {
            worker = worker
              .get('pdf')
              .then(pdf => {
                pdf.addPage()
              })
              .from(page)
              .toContainer()
              .toCanvas()
              .toPdf()
          }
        }

        worker = worker.save()
        return true;
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'exportToPDF', TYPE["0003"], error);
        return false;
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements(node, replacements){
      // console.log('setReplacements');
      for (const textElement of node.getElementsByTagName('text')) {
        for (const child of textElement.children) {
          if(child.tagName == 'tspan'){
            for (const commonReplacement of this.replacementsCommon) {
              if(child.innerHTML.indexOf(commonReplacement.key) != -1){
                this.setTspan(textElement, child, commonReplacement);
                break;
              }
            }
            for(let i = 0; i < replacements.length; i++){
              if(child.innerHTML.indexOf(replacements[i].key) != -1){
                this.setTspan(textElement, child, replacements[i]);
                replacements.splice(i, 1);
                break;
              }
            }
          }
        }
      }
      for (const gElement of node.getElementsByTagName('g')) {
        this.setReplacements(gElement, replacements);
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
      svgDoc.setAttribute('page-break-after', 'always');
    },
    /* 仮伝フラグ更新 */
    async updateFlg(orders) {
      // console.log('updateFlg');
      // 仮伝印刷フラグ、仮伝印刷日時を更新する
      let updateSQL = '';
      updateSQL += 'UPDATE T_HACCHU_MEISAI_DATA SET ';
      updateSQL += ' KARIDEN_INSATSU_FLG = 1,';
      updateSQL += ' URIDEN_NO = CASE';
      for (const data of orders) {
        updateSQL += ` WHEN HACCHU_DATA_ID = ${data.orderDataId}`;
        updateSQL += ` AND MEISAI_NO = ${data.orderBranchNo}`;
        updateSQL += ` THEN ${data.tempSlipNo}`;
      }
      updateSQL += ' END';
      updateSQL += ' WHERE (';
      for (let i = 0; i < orders.length; i++) {
        updateSQL += ` (HACCHU_DATA_ID = ${orders[i].orderDataId} AND MEISAI_NO = ${orders[i].orderBranchNo})`;
        if(i !== orders.length-1) {
          updateSQL += ' OR';
        }
      }
      updateSQL += ')';
      // console.log({updateSQL});
      if (!(await executeTransactSqls(this.$route.name, 'updateFlg', updateSQL))) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        return;
      }
    }
  },
}
</script>
<style>
  body
  {
    margin: 0;
    padding: 0;
    text-align: center;
  }
</style>